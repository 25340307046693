
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: #012b36;
  padding: 0 !important;
  border-radius: 5px;
  width: 80vw;
  height: 90vh;
  overflow-x: scroll;
}
