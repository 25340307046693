.highlight {
    color: #ffc803;
    font-weight: 700;
  }

  .mintHeader {
    position: sticky;
    width: 100%;
    text-align: center;
    top: 0;
    background: #012b36;
    display: flex;
    justify-content: center;
}

.closeButton {
    position: absolute;
    top: 0;
    right: 20px;
    z-index: 10;
    cursor: pointer;
}
.flipped {
    transform: scaleX(-1);
}